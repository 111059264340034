<template>
    <maintenance-page
        page-title="CostCentre"
        page-icon="mdi-chart-pie"
        collection-name="costcentre"
        done-redirect-url="/auth/admin/costcentre?isHistory=true"
    />
</template>

<script>
import MaintenancePage from '../../../components/pages/Maintenance';

export default {
    name: 'CustomerDetail',
    components: { MaintenancePage },
};
</script>
